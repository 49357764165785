import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import { Breadcrumbs, Loader } from 'components'
import { UserForm } from 'domains/user/components/UserForm/UserForm'

import ImageDriver from 'services/image'

import styles from './CreateUser.module.scss'
import { usePostUser } from 'domains/user/hooks/services/users/usePostUser'
import { useToast } from 'shared/hooks'
import { UserFormData } from 'domains/user/types'
import { paths } from 'routes'

const CreateUser = () => {
  const navigate = useNavigate()
  const { addToast } = useToast()

  const { mutateCreateUser, statusCreateUser } = usePostUser()

  const onSubmit = useCallback(
    (payload: UserFormData) => {
      mutateCreateUser(payload, {
        onSuccess: async (data) => {
          if (payload.avatarFile) {
            try {
              await ImageDriver.persist(payload.avatarFile as File, {
                principalOwner: {
                  id: data.id,
                  name: 'internalProfile',
                },
                owners: [
                  {
                    id: data.id,
                    name: 'internalProfile',
                  },
                ],
              })
            } catch (error) {
              console.debug('Erro ao criar imagem')
            }
          }
          addToast({ message: 'Usuário cadastrado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao cadastrar usuário. Tente novamente.',
            type: 'alert',
          })
        },
      })
    },
    [mutateCreateUser, navigate, addToast],
  )

  return (
    <div className={styles.container}>
      <Loader isVisible={statusCreateUser === 'pending'} />
      <Breadcrumbs
        items={[
          { title: 'Home', href: '/' },
          { title: 'Configurações', href: '' },
          {
            title: 'Gestão de usuários',
            href: paths.configuration.user.list + '?page=1',
          },
          { title: 'NOVO USUÁRIO', href: '' },
        ]}
      />
      <div className={styles.headerSeparator} />

      <UserForm onSubmit={onSubmit} onGoBack={() => navigate(-1)} />
    </div>
  )
}

export default CreateUser
